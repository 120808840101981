<template>
  <v-container fluid>
    <v-row justify="end" no-gutters>
      <v-col
        cols="auto"
        v-for="(item, i) in items_medicos"
        :key="i"
        class="mx-1"
      >
        <v-chip :color="item.color" dark>{{ item.nombre }}</v-chip>
      </v-col>
    </v-row>
    <v-row v-if="this.$store.state.usuario.nombre == 'User Devco'">
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-autocomplete
          v-model="item_value_medico"
          :items="items_medicos"
          item-text="nombre"
          item-value="id_medico"
          label="Médico"
          outlined
          dense
          hide-details
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="auto">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="grey"
              dark
              v-bind="attrs"
              v-on="on"
              @click="listarCupos()"
            >
              <v-icon> fa-solid fa-magnifying-glass </v-icon>
            </v-btn>
          </template>
          <span>Buscar</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="3" v-if="!esAdministrador">
        <v-form ref="form">
          <v-card class="mx-1" elevation="0">
            <v-card-title>Registrar cupo</v-card-title>
            <v-card-text class="pb-0">
              <v-text-field
                v-if="false"
                v-model="fechaCupo"
                label="Fecha"
                type="date"
                :rules="rules"
                prepend-icon="fa-solid fa-calendar"
              ></v-text-field>

              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-combobox
                    v-model="fechaCupo"
                    multiple
                    chips
                    small-chips
                    label="Fecha cupo"
                    placeholder="Seleccione las fechas"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-combobox>
                </template>
                <v-date-picker v-model="fechaCupo" multiple no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(fechaCupo)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-autocomplete
                v-model="id_medico"
                :items="itemsMedicos"
                label="Médico"
                item-text="nombre"
                item-value="id_medico"
                :rules="rules"
                prepend-icon="fa-solid fa-user-doctor"
              >
              </v-autocomplete>
              <v-text-field
                v-model="n_cupo"
                label="Disponibilidad"
                type="number"
                min="1"
                :rules="rules"
                onkeypress="return event.charCode >= 48"
                prepend-icon="fa-solid fa-hashtag"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="verificarEstado()"
                >Registrar cupo</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
      <v-col cols="12" md="9" class="mx-auto">
        <v-row>
          <v-col>
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn outlined color="grey darken-2" @click="setToday">
                  Hoy
                </v-btn>
                <v-btn fab text small @click="prev()">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn fab text small @click="next()">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer> </v-spacer>
                <div
                  class="mr-3 headline font-weight-bold"
                  v-if="$vuetify.breakpoint.mdAndUp ? true : false"
                >
                  Cupos registrados
                </div>
                <v-spacer></v-spacer>
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Día</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Semana</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Mes</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                      <v-list-item-title>4 Días</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :type="type"
                @click:event="showEvent"
                @change="superUsuario"
                @click:more="viewDay"
              ></v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-y
              >
                <v-card color="grey lighten-4" min-width="350px" flat>
                  <v-toolbar :color="selectedEvent.color" dark>
                    <v-toolbar-title></v-toolbar-title>
                  </v-toolbar>
                  <v-card-text class="px-0">
                    <v-toolbar elevation="0" color="transparent" dense>
                      <v-toolbar-title>Medico: </v-toolbar-title>
                      <span class="title mx-1">{{ selectedEvent.medico }}</span>
                    </v-toolbar>
                    <v-toolbar elevation="0" color="transparent" dense>
                      <v-toolbar-title>Fecha: </v-toolbar-title>
                      <span class="title mx-1">{{
                        formatoFechaV2(selectedEvent.start)
                      }}</span>
                    </v-toolbar>
                    <v-toolbar elevation="0" color="transparent" dense>
                      <v-toolbar-title>Cupos: </v-toolbar-title>
                      <v-text-field
                        v-model="selectedEvent.cupo"
                        onkeypress="return event.charCode >= 48"
                        type="number"
                        min="1"
                        hide-details
                        class="mx-4"
                        :disabled="esAdministrador"
                      ></v-text-field>
                    </v-toolbar>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text @click="selectedOpen = false" outlined>
                      Cerrar
                    </v-btn>
                    <v-btn
                      text
                      outlined
                      @click="actualizarCupo()"
                      v-if="!esAdministrador"
                    >
                      Actualizar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      outlined
                      @click="eliminarCupo()"
                      v-if="!esAdministrador"
                      >Eliminar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>

      <v-overlay v-model="overlay">
        <lottie-player
          autoplay
          loop
          mode="normal"
          src="https://assets6.lottiefiles.com/packages/lf20_uwR49r.json"
          style="width: 200px"
        >
        </lottie-player>
      </v-overlay>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "@lottiefiles/lottie-player";

export default {
  data: () => ({
    menu: false,
    id_usuario: 0,
    id_medico: 0,
    fechaCupo: [],
    itemsMedicos: [],
    itemsCupos: [],
    n_cupo: 1,
    n_cupo_ant: 0,
    estado: 0,
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Mes",
      week: "Semana",
      day: "Día",
      "4day": "4 Días",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    items_medicos: [],
    item_value_medico: 0,
    //otras variables
    rules: [(v) => !!v || "El campo es obligatorio"],
    overlay: false,
    contador: 0,
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  computed: {
    esAdministrador() {
      if (this.$store.state.usuario) {
        if (this.$store.state.usuario.rol == "Administrador") {
          return false;
        } else {
          return true;
        }
      }
    },
  },
  created() {
    this.verMedicos();
  },
  methods: {
    verMedicos() {
      axios.get("api/Medicos/Listar").then((response) => {
        //console.log(response.data);
        this.items_medicos = response.data;
      });
    },
    listarMedicos() {
      this.overlay = true;
      axios
        .get("api/Medicos/Listar")
        .then((response) => {
          this.overlay = false;
          response.data.forEach((element) => {
            if (element.condicion) {
              this.itemsMedicos.push(element);
            }
            if (
              element.id_consultorio == this.$store.state.usuario.id_consultorio
            ) {
              if (element.condicion) {
                this.id_medico = element.id_medico;
              }
            }
          });
        })
        .catch((error) => {
          this.overlay = false;
          this.alerta("Sucedio algún error", "error");
        });
    },
    listarMedicosxConsul() {
      this.overlay = true;
      axios
        .get(
          "api/Medicos/ListarxConsultorio/" +
            this.$store.state.usuario.id_consultorio
        )
        .then((response) => {
          this.overlay = false;
          this.itemsMedicos = response.data;
          this.itemsMedicos.forEach((element) => {
            if (
              element.id_consultorio == this.$store.state.usuario.id_consultorio
            ) {
              this.id_medico = element.id_medico;
            }
          });
          this.listarCupos();
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("Sucedio algún error", "error");
        });
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        this.n_cupo_ant = this.selectedEvent.cupo;

        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    superUsuario() {
      if (this.$store.state.usuario.super_usuario == "True") {
        this.listarCuposSuperUsuario();
        this.listarMedicos();
      } else {
        //this.listarCupos();
        this.listarMedicosxConsul();
      }
    },
    listarCuposSuperUsuario() {
      const events = [];
      axios
        .get("api/Cupos/Listar")
        .then((response) => {
          //console.log(response.data);
          response.data.forEach((element, index) => {
            events.push({
              medico: element.nombre_medico,
              name: "N° cupos: " + element.n_cupos,
              id_cupo: element.id_cupo,
              id_usuario: element.id_usuario,
              id_medico: element.id_medico,
              start: this.formatoFecha(element.fecha),
              end: "",
              color: element.color_medico,
              timed: true,
              cupo: element.n_cupos,
              estado: element.estado,
            });
          });
          this.events = events;
        })
        .catch(() => {
          this.alerta("Sucedio algún error", "error");
        });
    },
    listarCupos() {
      var id_medico =
        this.item_value_medico > 0 ? this.item_value_medico : this.id_medico;
      const events = [];
      axios
        .get("api/Cupos/VerCupos/" + id_medico)
        .then((response) => {
          response.data.forEach((element, index) => {
            events.push({
              medico: element.nombre_medico,
              name: "N° cupos: " + element.n_cupos,
              id_cupo: element.id_cupo,
              id_usuario: element.id_usuario,
              id_medico: element.id_medico,
              start: this.formatoFecha(element.fecha),
              end: "",
              color: element.color,
              timed: true,
              cupo: element.n_cupos,
              estado: element.estado,
            });
          });
          this.events = events;
        })
        .catch(() => {
          this.alerta("Sucedio algún error", "error");
        });
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    verificarEstado() {
      if (this.fechaCupo.length > 0 && this.id_medico && this.n_cupo) {
        this.estado = 0;
        this.contador = 0;

        this.fechaCupo.forEach((element, index) => {
          var bandera = false;
          axios
            .get(
              "api/Cupos/Listar/" +
                element +
                "/" +
                this.$store.state.usuario.idUsuario
            )
            .then((response) => {
              response.data.forEach((element) => {
                if (element.id_medico == this.id_medico) {
                  bandera = true;
                }
              });

              if (!bandera) {
                this.crearCupo(element, index + 1);
              }
            });
          this.contador++;
        });
      } else {
        this.$refs.form.validate();
      }
    },
    crearCupo(fecha_cupo, index) {
      this.overlay = true;
      axios
        .post("api/Cupos/Crear", {
          id_usuario: this.$store.state.usuario.idUsuario,
          id_medico: this.id_medico,
          fecha: fecha_cupo,
          n_cupos: this.n_cupo,
          estado: 0,
        })
        .then(() => {
          this.overlay = false;
          this.bitacora(
            "Se ha crea un nuevo cupo.",
            fecha_cupo,
            this.id_medico,
            this.n_cupo
          );
          if (index == this.fechaCupo.length) {
            this.alerta("Cupo/(s) registrado con exito", "success");
            this.limpiarVariables();
            this.superUsuario();
          }
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("Sucedio algún error", "error");
        });
    },
    actualizarCupo() {
      var cupos = 0;
      var mensaje = "";
      if (this.n_cupo_ant == this.selectedEvent.cupo) {
        cupos = this.selectedEvent.cupo;
        mensaje = "Se ha actualizado un cupo";
      } else if (this.n_cupo_ant > this.selectedEvent.cupo) {
        cupos = this.n_cupo_ant - this.selectedEvent.cupo;
        mensaje = "Se han quitado " + cupos + " cupos";
      } else if (this.n_cupo_ant < this.selectedEvent.cupo) {
        cupos = this.selectedEvent.cupo - this.n_cupo_ant;
        mensaje = "Se han incrementado " + cupos + " cupos";
      }
      this.overlay = true;
      axios
        .put("api/Cupos/Actualizar", {
          id_cupo: this.selectedEvent.id_cupo,
          id_usuario: this.selectedEvent.id_usuario,
          id_medico: this.selectedEvent.id_medico,
          fecha: this.selectedEvent.start,
          n_cupos: this.selectedEvent.cupo,
          estado: this.selectedEvent.estado,
        })
        .then(() => {
          this.overlay = false;
          this.alerta("Cupo actualizado con exito", "success");
          this.bitacora(
            mensaje,
            this.selectedEvent.start,
            this.selectedEvent.id_medico,
            this.selectedEvent.cupo
          );
          this.superUsuario();
        })
        .catch((error) => {
          this.overlay = false;
          this.alerta("Sucedio algún error", "error");
        });
    },
    eliminarCupo() {
      this.overlay = true;
      axios
        .delete(
          "api/Cupos/Eliminar/" +
            this.selectedEvent.id_cupo +
            "/" +
            this.$store.state.usuario.idUsuario
        )
        .then(() => {
          this.overlay = false;
          this.selectedOpen = false;
          this.alerta("Cupo eliminado con exito", "success");
          this.bitacora(
            "Se elimino un cupo",
            this.selectedEvent.start,
            this.selectedEvent.id_medico,
            0
          );
          this.superUsuario();
        })
        .catch((error) => {
          this.overlay = false;
        });
    },
    limpiarVariables() {
      this.id_usuario = 0;
      this.fechaCupo = [];
      this.n_cupo = 1;
      this.$refs.form.resetValidation();
      this.selectedOpen = false;
    },
    formatoFecha(fecha) {
      return moment(fecha).format("YYYY-MM-DD");
    },
    formatoFechaV2(fecha) {
      return moment(fecha).format("DD-MM-YYYY");
    },
    alerta(texto, tipo_alerta) {
      this.$swal({
        toast: true,
        position: "top-end",
        title: texto,
        icon: tipo_alerta,
        showConfirmButton: false,
        timer: 5000,
        backdrop: false,
      });
    },
    bitacora(descripcion, fecha_evento, id_medico, n_cupos) {
      var today = new Date();
      var fecha =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      axios
        .post("api/Bitacoras/Crear", {
          id_usuario: this.$store.state.usuario.idUsuario,
          fecha: fecha,
          descripcion: descripcion,
          fecha_evento: fecha_evento,
          medico_asignado: id_medico,
          paciente: "",
          n_cupos: n_cupos,
        })
        .catch((error) => {});
    },
  },
};
</script>